// import m from 'mithril'
import { env } from './config'
import request from './request'
import { checkFrontendVersionHeader } from './lib/software-update'

// const mockedRequest = (data, delay) => new Promise(resolve => setTimeout(resolve, delay || 500))
//   .then(() => data)
//   .finally(() => { m.redraw() })

const APIBaseURL = env.APIBaseURL

export const addCustomPosition = (offerId, data, attrs) => request.post(`${APIBaseURL}/crm/offers/${offerId}/add-position-custom/${data.product.productType}`, data, attrs)

export const addPosition = (offerId, data, attrs) => request.post(`${APIBaseURL}/crm/offers/${offerId}/add-position/${data.product.productType}`, data, attrs)

export const approveUser = userId => request.post(`${APIBaseURL}/users/${userId}/approve`)

export const changeConfiguratorUnitSystem = (data, detail = false) => request.post(`${APIBaseURL}/configurator/change-unit-system${detail ? '/detail/' : '/'}${data.productType}`, data)

export const changeCustomConfiguratorUnitSystem = (data, detail = false) => request.post(`${APIBaseURL}/custom-configurator/change-unit-system${detail ? '/detail/' : '/'}${data.productType}`, data)

export const changeUserEmail = (data, args) => request.post(`${APIBaseURL}/user/email`, data, args)

export const changeUserPassword = data => request.post(`${APIBaseURL}/user/password`, data)

export const checkAuth = () => request.get(`${APIBaseURL}/auth/check-auth`)

export const completeSignup = data => request.post(`${APIBaseURL}/auth/signup-completion`, data)

export const createCustomer = (data, args) => request.post(`${APIBaseURL}/crm/customers`, data, args)

export const createEnergeticAnalysisCustomCity = data => request.post(`${APIBaseURL}/configurator/energetic-analysis-custom/cities`, data)

export const createOffer = data => request.post(`${APIBaseURL}/crm/offers`, data)

export const createUser = (data, args) => request.post(`${APIBaseURL}/users`, data, args)

export const deleteCustomer = customerId => request.post(`${APIBaseURL}/crm/customers/${customerId}/delete`)

export const deleteEnergeticAnalysisCustomCity = cityId => request.delete(`${APIBaseURL}/configurator/energetic-analysis-custom/cities/${cityId}`)

export const deleteOffer = offerId => request.post(`${APIBaseURL}/crm/offers/${offerId}/delete`)

export const deletePosition = (offerId, positionId, productType) => request.delete(`${APIBaseURL}/crm/offers/${offerId}/positions/${positionId}/${productType}`)

export const disableUser = userId => request.post(`${APIBaseURL}/users/${userId}/disable`)

export const disableSystemNotification = () => request.delete(`${APIBaseURL}/admin/system-notification`)

export const dropUsersSessions = () => request.post(`${APIBaseURL}/acl/drop-sessions`)

export const editAclGroupsRules = data => request.post(`${APIBaseURL}/acl/groups/config `, data)

export const editAclRolesRules = data => request.post(`${APIBaseURL}/acl/roles/config`, data)

export const editCustomer = (customerId, data, args) => request.put(`${APIBaseURL}/crm/customers/${customerId}`, data, args)

export const editEnergeticAnalysisCustomCity = (cityId, data) => request.put(`${APIBaseURL}/configurator/energetic-analysis-custom/cities/${cityId}`, data)

export const editOffer = (offerId, data, args) => request.put(`${APIBaseURL}/crm/offers/${offerId}`, data, args)

export const editPosition = (offerId, positionId, productType, isCustom, data, args) => request.put(`${APIBaseURL}/crm/offers/${offerId}/${isCustom ? 'custom-' : ''}positions/${positionId}/${productType}`, data, args)

export const editUser = (userId, data, args) => request.put(`${APIBaseURL}/users/${userId}`, data, args)

export const editUserInfo = (data, args) => request.post(`${APIBaseURL}/user/info`, data, args)

export const enableUser = userId => request.post(`${APIBaseURL}/users/${userId}/enable`)

export const fetchTranslations = () => request.post(`${APIBaseURL}/admin/fetch-translations`)

export const forgotPassword = data => request.post(`${APIBaseURL}/auth/password-forgot`, data)

export const getAccessoriesGroups = () => request.get(`${APIBaseURL}/configurator/accessories-groups`)

export const getAclGroupsRules = () => request.get(`${APIBaseURL}/acl/groups/config`)

export const getAclRolesRules = () => request.get(`${APIBaseURL}/acl/roles/config`)

export const getBrands = () => request.get(`${APIBaseURL}/crm/brands`)

export const getCoilGeometries = () => request.get(`${APIBaseURL}/custom-configurator/coil-geometries`)

export const getConfiguratorExport = data => request.post(`${APIBaseURL}/configurator/export/${data.productType}`, data, { responseType: 'text' })

export const getConfiguratorResult = (data, params) => request.post(`${APIBaseURL}/configurator/detail/${data.productType}`, data, { params })

export const getConfiguratorResults = (data, args) => request.post(`${APIBaseURL}/configurator/search/${data.productType}`, data, args)

export const getCostingAirFlowTypes = () => request.get(`${APIBaseURL}/costing/air-fluxes`) // TODO change endpoint name

export const getCostingCircuits = () => request.get(`${APIBaseURL}/costing/circuits`)

export const getCostingCurves = params => request.get(`${APIBaseURL}/costing/curves`, { params })

export const getCostingDistributors = params => request.get(`${APIBaseURL}/costing/distributors`, { params })

export const getCostingExpansionTypes = params => request.get(`${APIBaseURL}/costing/expansions`, { params })

export const getCostingGeometries = () => request.get(`${APIBaseURL}/costing/geometries`)

export const getCostingFinMaterials = params => request.get(`${APIBaseURL}/costing/fin-materials`, { params })

export const getCostingFinThicknesses = params => request.get(`${APIBaseURL}/costing/fin-thicknesses`, { params })

export const getCostingFinTypes = params => request.get(`${APIBaseURL}/costing/fin-types`, { params })

export const getCostingFinWidths = params => request.get(`${APIBaseURL}/costing/fin-widths`, { params })

export const getCostingFluids = () => request.get(`${APIBaseURL}/costing/fluids`)

export const getCostingInletManifolds = params => request.get(`${APIBaseURL}/costing/inlet-manifolds`, { params })

export const getCostingManifoldEdgeShapes = () => request.get(`${APIBaseURL}/costing/manifold-edge-shapes`)

export const getCostingOutletManifolds = params => request.get(`${APIBaseURL}/costing/outlet-manifolds`, { params })

export const getCostingProductTypes = params => request.get(`${APIBaseURL}/costing/product-types`, { params })

export const getCostingReturnHeaders = params => request.get(`${APIBaseURL}/costing/return-headers`, { params })

export const getCostingTubeMaterials = params => request.get(`${APIBaseURL}/costing/tube-materials`, { params })

export const getCostingTubeTypes = params => request.get(`${APIBaseURL}/costing/tube-types`, { params })

export const getCountries = () => request.get(`${APIBaseURL}/crm/countries`)

export const getCustomConfiguratorResult = (data, params) => request.post(`${APIBaseURL}/custom-configurator/detail/${data.productType}`, data, { params })

export const getCustomEnergeticAnalysisCustomResults = data => request.post(`${APIBaseURL}/custom-configurator/energetic-analysis-custom`, data)

export const getCustomEnergeticAnalysisResults = data => request.post(`${APIBaseURL}/custom-configurator/energetic-analysis`, data)

export const getCustomFans = () => request.get(`${APIBaseURL}/custom-configurator/custom-fans`)

export const getCustomProductConfig = productId => request.get(`${APIBaseURL}/custom-configurator/product-config/${productId}`)

export const getCustomer = customerId => request.get(`${APIBaseURL}/crm/customers/${customerId}`)

export const getCustomerTypes = () => request.get(`${APIBaseURL}/crm/customer-types`)

export const getCustomers = params => request.get(`${APIBaseURL}/crm/customers`, { params })

export const getDraftPrintOutput = (offerId, unitSystem, lang) => request.get(`${APIBaseURL}/crm/offers-drafts/${offerId}/print-output?unitSystem=${unitSystem}${lang ? `&lang=${lang}` : ''}`)

export const getEnergeticAnalysisCities = countryId => request.get(`${APIBaseURL}/configurator/energetic-analysis/cities?countryId=${countryId}`)

export const getEnergeticAnalysisCountries = () => request.get(`${APIBaseURL}/configurator/energetic-analysis/countries`)

export const getEnergeticAnalysisCurrencies = () => request.get(`${APIBaseURL}/configurator/energetic-analysis/currencies`)

export const getEnergeticAnalysisCustomCities = params => request.get(`${APIBaseURL}/configurator/energetic-analysis-custom/cities`, { params })

export const getEnergeticAnalysisCustomCity = cityId => request.get(`${APIBaseURL}/configurator/energetic-analysis-custom/cities/${cityId}`)

export const getEnergeticAnalysisCustomResults = (data, params) => request.post(`${APIBaseURL}/configurator/energetic-analysis-custom`, data, { params })

export const getEnergeticAnalysisResults = (data, params) => request.post(`${APIBaseURL}/configurator/energetic-analysis`, data, { params })

export const getFans = () => request.get(`${APIBaseURL}/custom-configurator/fans`)

export const getFinMaterials = params => request.get(`${APIBaseURL}/custom-configurator/fin-materials`, { params })

export const getFinSpacings = () => request.get(`${APIBaseURL}/configurator/fin-spacings`)

export const getFinTypes = params => request.get(`${APIBaseURL}/custom-configurator/fin-types`, { params })

export const getFluidsLiquids = params => request.get(`${APIBaseURL}/configurator/fluids/liquids`, { params })

export const getFluidsRefrigerants = params => request.get(`${APIBaseURL}/configurator/fluids/refrigerants`, { params })

export const getGroups = () => request.get(`${APIBaseURL}/acl/groups?isEnabled=true`)

export const getMaintenanceMode = () => request.get(`${APIBaseURL}/maintenance-mode`)

export const getOffer = offerId => request.get(`${APIBaseURL}/crm/offers/${offerId}`)

export const getOfferDraft = offerId => request.get(`${APIBaseURL}/crm/offers/${offerId}/draft`)

export const getOffers = params => request.get(`${APIBaseURL}/crm/offers`, { params })

export const getPosition = (offerId, positionId, productType, unitSystem = 'SI', lang) => request.get(`${APIBaseURL}/crm/offers/${offerId}/positions/${positionId}/${productType}?unitSystem=${unitSystem}${lang ? `&lang=${lang}` : ''}`)

export const getPositionFromRevision = (offerId, revisionId, positionId, productType, unitSystem = 'SI', lang) => request.get(`${APIBaseURL}/crm/offers/${offerId}/revisions/${revisionId}/positions/${positionId}/${productType}?unitSystem=${unitSystem}${lang ? `&lang=${lang}` : ''}`)

export const getProductConfig = productId => request.get(`${APIBaseURL}/configurator/product-config/${productId}`)

export const getRevisionPrintOutput = (revisionId, unitSystem = 'SI', lang) => request.get(`${APIBaseURL}/crm/offers-revisions/${revisionId}/print-output?unitSystem=${unitSystem}${lang ? `&lang=${lang}` : ''}`)

export const getRoles = () => request.get(`${APIBaseURL}/acl/roles`)

export const getSheetMaterials = () => request.get(`${APIBaseURL}/costing/sheet-materials`)

export const getSystemNotification = args => request.get(`${APIBaseURL}/admin/system-notification`, args)

export const getSystemNotificationPreview = i18nKey => request.get(`${APIBaseURL}/admin/system-notification-preview?i18nKey=${i18nKey}`)

export const getTubeMaterials = params => request.get(`${APIBaseURL}/custom-configurator/tube-materials`, { params })

export const getTubeTypes = params => request.get(`${APIBaseURL}/custom-configurator/tube-types`, { params })

export const getUser = userId => request.get(`${APIBaseURL}/users/${userId}`)

export const getUserActions = () => request.get(`${APIBaseURL}/user/actions`)

export const getUserProfile = () => request.get(`${APIBaseURL}/user`)

export const getUsers = params => request.get(`${APIBaseURL}/users`, { params })

export const getUsersExport = () => request.get(`${APIBaseURL}/users/export`, { responseType: 'text' })

export const login = (data, args) => request.post(`${APIBaseURL}/auth/login`, data, args)

export const printCustomEnergeticAnalysis = data => request.post(`${APIBaseURL}/custom-configurator/energetic-analysis/print`, data, {
  extract: xhr => {
    checkFrontendVersionHeader(xhr)
    return {
      blob: xhr.response
    }
  },
  responseType: 'blob'
})

export const printEnergeticAnalysis = data => request.post(`${APIBaseURL}/configurator/energetic-analysis/print`, data, {
  extract: xhr => {
    checkFrontendVersionHeader(xhr)
    return {
      blob: xhr.response
    }
  },
  responseType: 'blob'
})

export const printOfferDraft = (offerId, data, isWordFormat) => request.post(
  isWordFormat
    ? `${APIBaseURL}/crm/offers-print-word-draft/${offerId}`
    : `${APIBaseURL}/crm/offers-print-draft/${offerId}`,
  data,
  {
    extract: xhr => {
      checkFrontendVersionHeader(xhr)
      return {
        blob: xhr.response,
        contentDisposition: xhr.getResponseHeader('Content-Disposition'),
        revisionId: xhr.getResponseHeader('Offer-Revision-Id'),
        code: xhr.status
      }
    },
    responseType: 'blob'
  }
)

export const printOfferRevision = (revisionId, data, isWordFormat) => request.post(
  isWordFormat
    ? `${APIBaseURL}/crm/offers-print-word-revision/${revisionId}`
    : `${APIBaseURL}/crm/offers-print-revision/${revisionId}`,
  data,
  {
    extract: xhr => {
      checkFrontendVersionHeader(xhr)
      return {
        blob: xhr.response,
        contentDisposition: xhr.getResponseHeader('Content-Disposition'),
        code: xhr.status
      }
    },
    responseType: 'blob'
  }
)

export const printPositionSummary = (data, isWordFormat) => request.post(
  isWordFormat
    ? `${APIBaseURL}/crm/offers-print-word-position-summary`
    : `${APIBaseURL}/crm/offers-print-position-summary`,
  data,
  {
    extract: xhr => {
      checkFrontendVersionHeader(xhr)
      return {
        blob: xhr.response,
        contentDisposition: xhr.getResponseHeader('Content-Disposition'),
        code: xhr.status
      }
    },
    responseType: 'blob'
  }
)

export const rebuildOffer = offerId => request.post(`${APIBaseURL}/crm/offers/${offerId}/rebuild`)

export const refreshSearchIndex = () => request.post(`${APIBaseURL}/admin/refresh-search-index`)

export const resendSignupCompletion = email => request.post(`${APIBaseURL}/auth/signup-completion-resend`, { email })

export const resendVerificationEmail = email => request.post(`${APIBaseURL}/auth/email-verification-resend`, { email })

export const resetPassword = data => request.post(`${APIBaseURL}/auth/password-reset`, data)

export const searchCustomProducts = (s, config) => request.get(`${APIBaseURL}/custom-configurator/search-products`, { params: { s }, config })

export const searchProducts = (s, config) => request.get(`${APIBaseURL}/configurator/search-products`, { params: { s }, config })

export const setMaintenanceMode = isEnabled => request.post(`${APIBaseURL}/maintenance-mode`, { isEnabled })

export const setSystemNotification = data => request.post(`${APIBaseURL}/admin/system-notification`, data)

export const setUserGroups = (userId, data) => request.post(`${APIBaseURL}/users/${userId}/groups`, data)

export const setUserRoles = (userId, data) => request.post(`${APIBaseURL}/users/${userId}/roles`, data)

export const signup = data => request.post(`${APIBaseURL}/auth/signup`, data)

export const sortPosition = (offerId, positionId, data) => request.post(`${APIBaseURL}/crm/offers/${offerId}/positions/${positionId}/sort-order`, data)

export const updateTranslations = () => request.post(`${APIBaseURL}/admin/update-translations`)

export const verifyEmail = (data, args) => request.post(`${APIBaseURL}/auth/email-verification`, data, args)
