import m from 'mithril'
import translatejs from 'translate.js'
import { localStorageKeys } from '../config'
import state from '../models/state'
import store from '../lib/store'
// i18n translations
const languages = [
  'en',
  'it',
  'fr',
  'de',
  'es',
  'ru',
  'cs',
  'sv'
]

const translations = {}

const options = {
  debug: true
}

let t
let currentLanguage

const translate = (key, args) => t(key, args)

// Format the translations object with translate.js pluralization convention
const parseTranslationStrings = data => {
  for (const key in data) {
    if ((key.match(/\./g) || []).length > 1) {
      if (!data[key.slice(0, -2)]) data[key.slice(0, -2)] = {}
      data[key.slice(0, -2)][key.slice(-1)] = data[key]
    }
  }
  return data
}

const setLanguage = lang => {
  currentLanguage = lang && languages.includes(lang) ? lang : 'en'
  return (translations[currentLanguage]
    ? Promise.resolve(translations[currentLanguage])
    : m.request(`/i18n/${currentLanguage}.json`))
    .then(response => {
      translations[currentLanguage] = parseTranslationStrings(response)
      return response
    })
}

translate.languages = languages

translate.init = lang => setLanguage(lang)
  .then(response => {
    t = translatejs(response, options)
    state.update({ currentLanguage })
    store.set(localStorageKeys.currentLanguage, currentLanguage)
  })

translate.getLocalTranslator = lang => setLanguage(lang)
  .then(response => translatejs(response, options))

translate.arr = (key, args) => t.arr(key, args)

translate.getCurrentLanguage = () => currentLanguage

export default translate
