import m from 'mithril'
import t from '../../../lib/translate'
import SubmitButton from '../forms/submit-button'

const Modal = ({ attrs }) => {
  let loading = false
  const close = () => attrs.close ? attrs.close() : null

  const submit = () => {
    loading = true
    return (attrs.submit ? attrs.submit() : Promise.resolve())
      .then(() => {
        loading = false
      })
  }

  const keypressHandler = e => {
    const code = e.keyCode ? e.keyCode : e.which
    if (code === 27) { // ESC key
      close()
      m.redraw()
    }
  }

  function oncreate ({ dom }) {
    document.body.classList.add('no-scrollable')
    document.addEventListener('keyup', keypressHandler)
    setTimeout(() => {
      dom.classList.add('open')
    }, 100)
  }

  function onbeforeremove ({ dom }) {
    dom.classList.remove('open')
    return new Promise(resolve => {
      setTimeout(resolve, 200)
    })
  }

  function onremove () {
    document.body.classList.remove('no-scrollable')
    document.removeEventListener('keyup', keypressHandler)
    if (attrs.onClose) attrs.onClose()
  }

  function view ({ attrs, children }) {
    return m('.modal.flex.flex-rows', {
      className: attrs.className
    }, [
      m('.modal-background', {
        onclick () {
          if (!attrs.noClose) close()
        }
      }),
      m('.modal-content.content', [
        m('.box', [
          m('h3.modal-title', attrs.title),
          m('.pb3', children),
          m('.m-modal-footer.has-text-right', attrs.buttons || [
            m('button.button.mr1', {
              className: attrs.cancelClassName,
              type: 'button',
              onclick: close,
              'aria-label': t('common.close')
            }, t('common.cancel')),
            m(SubmitButton, {
              className: attrs.submitClassName,
              disabled: attrs.disabled || loading || false,
              onClick: submit,
              loading: loading || false
            }, attrs.submitLabel || t('common.confirm'))
          ])
        ])
      ]),
      !attrs.noClose && m('button.modal-close.is-large', {
        type: 'button',
        onclick: close,
        title: t('common.close'),
        'aria-label': t('common.close')
      })
    ])
  }

  return {
    oncreate,
    onbeforeremove,
    onremove,
    view
  }
}

export default Modal
