import hash from 'blueimp-md5'
import mergerino from 'mergerino'
import { localStorageKeys } from '../config'
import { createState } from '../lib/meiosis-custom'
import { objClone } from '../lib/object-utils'
import store from '../lib/store'

const maxViewTimes = 3

const initialState = {
  accessoriesGroups: null,
  actions: [],
  activeSystemNotification: null,
  costingCoilGeometries: null,
  currentLanguage: 'en',
  fans: null,
  finSpacings: null,
  finTypes: null,
  fluids: {},
  hasUpdateAvailable: false,
  materials: null,
  prevPath: null,
  refrigerants: null,
  roles: [],
  systemNotifications: [],
  tubeTypes: null,
  unitSystem: 'SI',
  user: store.get(localStorageKeys.user),
  viewport: null
}

initialState.version = hash(JSON.stringify(initialState))

const reducer = (then, now) => {
  if (now.reset) {
    delete now.reset
    return now
  }
  if (now.unitSystem) {
    store.set(localStorageKeys.unitSystem, now.unitSystem)
  }
  // SYSTEM NOTIFICATIONS
  if (now.activeSystemNotification !== undefined) {
    if (now.activeSystemNotification === null) {
      // activeSystemNotification is set to null, meaning that there are no active notifications at login
      // We can then empty the stored notifications
      now.systemNotifications = []
      store.set(localStorageKeys.systemNotifications, [])
    } else {
      // We have a notification update, coming from login or from modal dismission
      const notification = objClone(now.activeSystemNotification)
      // Check if the same notification for the same user has already previously been stored
      const sameNotification = then.systemNotifications.find(n => n.userId === notification.userId && n.i18nKey === notification.i18nKey)
      if (sameNotification) {
        // The the same notification for the same user has already previously been stored
        if (notification.isAccepted) {
          // The modal has been dismissed, increasing the view counter
          notification.viewTimes = (sameNotification.viewTimes || 0) + 1
          now.activeSystemNotification = null
        } else {
          // Notification comes from login response
          notification.viewTimes = sameNotification.viewTimes
          // Don't show it if it has already been dismissed the required amount of times
          if (notification.viewTimes >= maxViewTimes) {
            now.activeSystemNotification = null
          }
        }
        // Update existing notification in stored notifications
        now.systemNotifications = [...then.systemNotifications.filter(n => !(n.userId === notification.userId && n.i18nKey === notification.i18nKey)), notification]
        store.set(localStorageKeys.systemNotifications, now.systemNotifications)
      } else {
        // Add new notification to stored notifications
        now.systemNotifications = [...then.systemNotifications, notification]
        store.set(localStorageKeys.systemNotifications, now.systemNotifications)
      }
    }
  } else if (now.systemNotifications && then.user) {
    // Read stored notifications at app startup (set from index.js)
    now.activeSystemNotification = now.systemNotifications.find(n => n.userId === then.user.id && !n.isAccepted && (!n.viewTimes || n.viewTimes < maxViewTimes))
  }

  return mergerino(then, now)
}

// Create configurator state
const configuratorState = createState(reducer, initialState)

export default configuratorState
