import m from 'mithril'
import Icon from '../icon/icon'

const message = ({ className, icon, iconClasses = ['fa-fw', 'fa-2x', 'mr2'], message } = {}) => m('.message', {
  className: className
}, [
  m('.message-body', [
    m('.flex.flex-cols', [
      m(Icon, { name: icon, classes: iconClasses }),
      message
    ])
  ])
])

export default message
