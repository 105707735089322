import m from 'mithril'
import { icon as faIcon } from '@fortawesome/fontawesome-svg-core'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'

let icons
let loadingIcons = false
const renderedIcons = {}

const loadIcons = () => {
  loadingIcons = true
  return import('../../../lib/icons.js')
    .then(module => {
      icons = module
      loadingIcons = false
      m.redraw()
    })
}

const renderIcon = ({ name, classes = [], title } = {}) => {
  if (!icons[name]) console.warn(`Icon ${name} is missing in the library`)
  const key = `${name}-${classes.join('-')}`
  if (!renderedIcons[key]) {
    renderedIcons[key] = faIcon(icons[name] || faQuestion, { classes }).abstract[0]
  }
  return m('svg', {
    ...renderedIcons[key].attributes
  }, m('path', {
    ...renderedIcons[key].children[0].attributes
  }), title && m('title', title))
}

const Icon = () => {
  if (!icons && !loadingIcons) loadIcons()

  function view ({ attrs }) {
    return icons && renderIcon(attrs)
  }

  return { view }
}

export default Icon
