import m from 'mithril'

const NavBarDropdown = () => {
  let open = false

  const clickHandler = () => {
    open = !open
    m.redraw()
    if (!open) document.removeEventListener('click', clickHandler)
  }

  function view ({ attrs, children }) {
    return m('.navbar-item.has-dropdown', {
      className: open ? 'is-active' : ''
    }, [
      m('span.navbar-link.has-text-primary.is-unselectable', {
        onclick: (e) => {
          e.preventDefault()
          document.addEventListener('click', clickHandler)
        }
      }, attrs.text),
      m('.navbar-dropdown.is-right', children)
    ])
  }

  return { view }
}

export default NavBarDropdown
