import stream from 'mithril/stream'
import m from 'mithril'

const notifications = stream([])

const current = () => notifications()[0]

const popup = notification => {
  notifications([
    ...notifications(),
    {
      type: 'info', // 'info', 'success', 'danger', 'warning'
      message: '...',
      action: null,
      permanent: false,
      id: Date.now(),
      ...notification
    }
  ])
}

const close = (id) => {
  notifications(notifications().filter(x => x.id !== id))
}

// DEBUG test notification bombing
// const test = () => setInterval(() => {
//   const types = ['info', 'success', 'danger', 'warning']
//   popup({
//     type: types[Math.floor(Math.random() * types.length)],
//     message: `Prova prova prova ${Date.now()}`,
//     permanent: Math.random() >= 0.5
//   })
// }, 1000)
// test()

const notifyError = response => {
  popup({
    type: 'danger',
    message: [
      response.title,
      response.detail && m('.is-size-7', response.detail),
      response.errors && m('.content.is-size-7', [
        m('ul', Object.keys(response.errors).map(key => m('li', [
          m('strong', key),
          m('br'),
          response.errors[key].join(m('br'))
        ])))
      ])
    ]
  })
}

export {
  notifications,
  current,
  popup,
  close,
  notifyError
}
