import { objClone } from '../lib/object-utils'
import mergerino from 'mergerino'
import throttle from 'lodash.throttle'
import { customConfiguratorDefaults, CustomConfiguratorValidator } from './custom-configurator-defaults'
import { env, localStorageKeys } from '../config'
import { createState } from '../lib/meiosis-custom'
import store from '../lib/store'
import t from '../lib/translate'

const logValidationErrors = throttle((errors, state) => {
  console.error('CUSTOM CONFIGURATOR VALIDATION ERRORS')
  console.log(JSON.stringify(errors, null, 2))
  console.log(state)
}, 2000, { leading: false, trailing: true })

// This whole approach assumes that the various
// properties of your model have sufficiently complex
// interdependencies that using some combination
// of dependent, merged, LIFTed, and/or combined streams
// isn't helping reduce the complexity of your app.
// Which is to say: updates to the form should probably
// be handled fairly imperatively.
export const reducer = (old, now, { skipLogs = false } = {}) => {
  const then = objClone(old)
  // RESET LOGIC
  if (now.reset) {
    delete now.reset
    return now
  }

  // Refresh the whole configurator
  let refresh = false

  const newProductType = now.productType || (now.productConfig ? now.productConfig.productType : null)
  const hasChangedProductType = !!newProductType && then.previouslySelectedProductType !== newProductType

  // Respond to macro changes (productType or productConfig)
  if ((now.productType && then.productType !== now.productType) || now.productConfig) {
    if (now.productType) {
      now.previouslySelectedProductType = now.productType
      if (!now.productConfig) {
        then.productConfig = null
        then.productId = null
      }
    }
    then.priority = customConfiguratorDefaults.priority
    if (hasChangedProductType) {
      then.fluid.fluidType = customConfiguratorDefaults.fluid.fluidType
    }
    refresh = true
  }

  // Respond to unit of measurement changes
  if (now.unitSystem) refresh = true

  // -------------------------------
  // MERGE UPDATES
  const state = mergerino(then, now)
  // -------------------------------

  // Refresh
  if (refresh) state.key = Date.now()

  // PRODUCT TYPE
  const isACC = state.productType === 'acc'
  const isLC = state.productType === 'lc'
  const isLHP = state.productType === 'lhp'
  const isLTHT = state.productType === 'ltht'

  // PRIORITY
  state.ui.showPriority = isLHP

  // AIR
  // Show cool and hot inlet air temperature and relative humidity (inputs and data) - LHP only
  state.ui.air.show.cool = isLHP && state.priority !== 'onlyHeating'
  state.ui.air.show.hot = isLHP && state.priority !== 'onlyCooling'

  // FLUID
  // Set the default refrigerant fluid according to the product type
  if (!state.fluid.fluidType) state.fluid.fluidType = isACC ? 4 : 6 // TODO hardcoded
  if (!state.fluidLT.fluidType) state.fluidLT.fluidType = 6 // TODO hardcoded
  if (!state.fluidHT.fluidType) state.fluidHT.fluidType = 6 // TODO hardcoded
  // Show gas temperature inputs and data - ACC only
  state.ui.fluid.show.gas = isACC
  // Show cool and hot inlet and outlet water temperature (inputs and data) - LHP only
  state.ui.fluid.show.cool = isLHP && state.priority !== 'onlyHeating'
  state.ui.fluid.show.hot = isLHP && state.priority !== 'onlyCooling'

  // DUTY
  // Show the double circuit option checkbox and second circuit capacity data - ACC only
  state.ui.duty.show.doubleCircuitOption = isACC
  // Show the water flow input and data - LC/LHP only
  state.ui.duty.show.waterFlow = isLC || isLHP || isLTHT
  // Show max liquid pressure drop field - LC/LHP only
  state.ui.duty.show.maxLiquidPressureDrop = isLC || isLHP || isLTHT
  // Disable max liquid pressure drop field when a tubeSeriesNumber value is provided
  state.ui.duty.disableMaxLiquidPressureDrop = !!state.coils.tubeSeriesNumber && state.coils.tubeSeriesNumber !== '-'

  // LINKED FIELDS
  // Manage fields linked with physical equation
  // Manage fields linked by type and range
  const validateLinkedFields = values => {
    const valuesLength = values.length
    if (valuesLength && values.filter(x => x != null && x !== '').length !== valuesLength - 1) {
      return t('configurator.linked_fields_error', { fields: valuesLength - 1, total: valuesLength })
    }
  }
  if (isLC) {
    state.errors.linkedFields = validateLinkedFields([
      state.fluid.inputTemperature,
      state.fluid.outputTemperature,
      state.duty.waterFlow
    ])
  } else if (isLHP) {
    const fluidCoolTemperaturesValues = [
      state.fluid.inputTemperatureCool,
      state.fluid.outputTemperatureCool
    ]
    const fluidHotTemperaturesValues = [
      state.fluid.inputTemperatureHot,
      state.fluid.outputTemperatureHot
    ]
    state.errors.linkedFields = validateLinkedFields([
      state.duty.waterFlow,
      ...(state.priority === 'heating' || state.priority === 'onlyHeating' ? fluidHotTemperaturesValues : fluidCoolTemperaturesValues)
    ])
  } else if (isLTHT) {
    state.errors.linkedFieldsLT = validateLinkedFields([
      state.fluidLT.inputTemperature,
      state.fluidLT.outputTemperature,
      state.dutyLT.waterFlow
    ])
    state.errors.linkedFieldsHT = validateLinkedFields([
      state.fluidHT.inputTemperature,
      state.fluidHT.outputTemperature,
      state.dutyHT.waterFlow
    ])
  }
  if (!state.errors.linkedFields) delete state.errors.linkedFields
  if (!state.errors.linkedFieldsLT) delete state.errors.linkedFieldsLT
  if (!state.errors.linkedFieldsHT) delete state.errors.linkedFieldsHT

  // Show form (hide it when required data are missing)
  state.ui.showForm = !!state.productType || !!state.productConfig

  // Set fanPercentage to maximum if fan main type is 'AC'
  state.ui.fans.disableFanPercentage = state.fans.mainTypeSlug === 'AC'
  if (state.ui.fans.disableFanPercentage) state.fans.fanPercentage = 100

  // Allow form submit (deny if required data are missing or there are validation errors)
  state.ui.allowSubmit = state.ui.showForm && !Object.keys(state.errors).length
  if (Object.keys(state.errors).length) console.log('CUSTOM CONFIGURATOR ERRORS', state.errors)

  const validationErrors = CustomConfiguratorValidator.validate(mergerino(state, { errors: undefined })) // TODO TEMP, skip validation instead
  if (validationErrors && !skipLogs) logValidationErrors(validationErrors, state)

  return state
}

// Create configurator state
const customConfiguratorState = createState(reducer, customConfiguratorDefaults, localStorageKeys.ccs)

// State persistence (on LocalStorage)
customConfiguratorState.map(throttle(state => {
  store.set(localStorageKeys.ccs, state)
}, 2000, { leading: false, trailing: true }))

// Debug
if (env.isDevelop) window.customConfigurator = () => customConfiguratorState()

export default customConfiguratorState
