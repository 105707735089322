import m from 'mithril'
import { env } from './config'
import { getToken } from './models/auth'
import state from './models/state'
import { log } from './lib/log'
import { notifyError } from './lib/popup-notifications'
import { checkFrontendVersionHeader } from './lib/software-update'

const request = opts => {
  const options = {
    ...opts,
    headers: {}
  }

  // Authentication token
  const token = getToken()
  if (token) options.headers.Authorization = `Bearer ${token}`

  options.headers['Accept-Language'] = state().currentLanguage || 'en'

  // Read response headers
  if (!options.extract) {
    options.responseType = opts.responseType || 'json'
    options.extract = xhr => {
      checkFrontendVersionHeader(xhr)
      if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
        return xhr.response
      } else {
        if (xhr.status === 503) m.route.set('/maintenance')
        const error = new Error(xhr.statusText)
        error.code = xhr.status
        error.response = xhr.response
        throw error
      }
    }
  }

  return m.request(options)
    // Manage errors for BLOB responses
    .then(response => response && response.blob && response.code >= 400
      ? response.blob.text()
        .then(text => Promise.reject({
          code: response.code,
          response: JSON.parse(text)
        }))
      : response
    )
    // Manage errors for standard JSON responses
    .catch(error => {
      // [DEV only] Log error and options
      if (env.isDevelop) {
        console.error(`[API request error ${error.code}${error.response && error.response.title ? ` - ${error.response.title}` : ''}]`)
        console.log('[Request options]', options)
      }

      // Handle unauthenticated requests
      if (error.code === 401) {
        // Redirect unauthenticated user to login screen
        m.route.set('/login')
      } else {
        // Log other errors
        log(error.response)
      }

      // Otherwise display error title and detail in popup notification
      if (!opts.skipErrorNotifications && error.response && error.response.title && error.code !== 422) notifyError(error.response)

      return Promise.reject(error.response)
    })
}

const buildRequest = (method, url, body, args) => request({
  method,
  url,
  ...body && { body },
  ...(args && typeof args === 'object') && { ...args }
})

export default {
  get: (url, args) => buildRequest('GET', url, null, args),

  post: (url, body, args) => buildRequest('POST', url, body, args),

  put: (url, body, args) => buildRequest('PUT', url, body, args),

  delete: (url, args) => buildRequest('DELETE', url, null, args)
}
