import m from 'mithril'
import state from '../../models/state'

const ResponsiveSize = () => {
  function view ({ attrs }) {
    const viewport = state().viewport
    return m('.responsive-size', {
      style: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: '.125rem .25rem',
        'background-color': 'rgba(0,0,0,.8)',
        color: 'white',
        'font-size': '.75rem'
      }
    }, [
      m('.hide.is-block-mobile', 'mobile (up to 768px)'),
      m('.hide.is-block-tablet-only', 'tablet (769px <-> 1087px)'),
      m('.hide.is-block-desktop-only', 'desktop (1088px <-> 1279px)'),
      m('.hide.is-block-widescreen-only', 'widescreen (1280px <-> 1471px)'),
      m('.hide.is-block-fullhd', 'fullhd (1472px and above)'),
      ` [${viewport.width}x${viewport.height}, ${viewport.size}]`
    ])
  }

  return { view }
}

export default ResponsiveSize
