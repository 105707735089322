import { env } from '../config'
import state from '../models/state'

export const checkFrontendVersionHeader = xhr => {
  const remoteFrontendVersion = xhr.getResponseHeader('Frontend-Version')
  if (remoteFrontendVersion && env.frontendVersion && !remoteFrontendVersion.includes(env.frontendVersion)) {
    state.update({
      hasUpdateAvailable: true
    })
  }
}
