import { unitsOfMeasurementDecimals } from './units-of-measurement'
import { objClone } from '../lib/object-utils'
import { floatToFixedPointStr, intToStr, isNum } from '../lib/format-utils'

export const formatUnitSystemSwitch = (data, isCustom) => {
  const p = objClone(data)

  Object.keys(p.air).forEach(k => {
    if (isNum(p.air[k])) p.air[k] = floatToFixedPointStr(p.air[k])
    else if (p.air[k] === null) p.air[k] = ''
  })

  Object.keys(p.fluid).forEach(k => {
    if (isNum(p.fluid[k]) && k !== 'fluidType') p.fluid[k] = floatToFixedPointStr(p.fluid[k])
    else if (p.fluid[k] === null) p.fluid[k] = ''
  })

  Object.keys(p.duty).forEach(k => {
    if (isNum(p.duty[k]) && k !== 'tolerance') p.duty[k] = floatToFixedPointStr(p.duty[k])
    else if (p.duty[k] === null) p.duty[k] = ''
    else if (k === 'tolerance' && isNum(p.duty[k])) p.duty[k] = p.duty[k].toString()
  })
  if (isCustom) {
    delete p.duty.circuitCapacity
    delete p.duty.tolerance
    delete p.duty.capacityMargin
    delete p.duty.surfaceMargin
  }

  if (p.dimensions) {
    Object.keys(p.dimensions).forEach(k => {
      if (isNum(p.dimensions[k])) p.dimensions[k] = intToStr(p.dimensions[k])
      else if (p.dimensions[k] === null) p.dimensions[k] = ''
    })
  }

  Object.keys(p.ventilationNoise).forEach(k => {
    if (isNum(p.ventilationNoise[k])) p.ventilationNoise[k] = floatToFixedPointStr(p.ventilationNoise[k])
  })

  if (p.tubeSeriesNumber === null) p.tubeSeriesNumber = '-'

  if (p.ventilationPowerSource === null) p.ventilationPowerSource = '-'

  if (p.energyEfficiencyClass === null) p.energyEfficiencyClass = '-'

  if (isNum(p.maxPowerConsumption)) p.maxPowerConsumption = intToStr(p.maxPowerConsumption)
  else if (p.maxPowerConsumption === null) p.maxPowerConsumption = ''

  if (p.finSpacing) p.finSpacing = `${p.finSpacing}`
  if (p.finThickness) p.finThickness = `${p.finThickness}`

  if (p.coils) {
    p.coils.tubeThickness = floatToFixedPointStr(p.coils.tubeThickness, unitsOfMeasurementDecimals[p.unitSystem].lengthSmallAlt)
    p.coils.finThickness = floatToFixedPointStr(p.coils.finThickness, unitsOfMeasurementDecimals[p.unitSystem].lengthSmaller)
    p.coils.finSpacing = floatToFixedPointStr(p.coils.finSpacing, unitsOfMeasurementDecimals[p.unitSystem].lengthSmall)
    p.coils.finnedCoilLength = floatToFixedPointStr(p.coils.finnedCoilLength, unitsOfMeasurementDecimals[p.unitSystem].lengthSmall)
  }

  // TODO temp - temporarily remove radial family
  if (p.family) delete p.family.radial

  delete p.onlyEvenTubes
  if (p.ventilationNoise) {
    delete p.ventilationNoise.soundPowerLw
    delete p.ventilationNoise.soundPressureLp
  }

  return p
}
