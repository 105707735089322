import m from 'mithril'
import Footer from './_footer'
import Header from './_header'
import Modal from '../common/modal/modal'
import PopupNotifications from '../common/popup-notifications/popup-notifications'
import ResponsiveSize from './_responsive-size'
import { env } from '../../config'
import { isAuth } from '../../models/auth'
import state from '../../models/state'
import t from '../../lib/translate'

const Layout = () => {
  function view ({ attrs, children }) {
    const s = state()
    const isPublic = !isAuth() // attrs.app.section === 'login' || attrs.app.section === 'password-forgot'
    const isMaintenance = attrs.app.section === 'maintenance'
    return m('.wrap.fadein', {
      key: `wrap-${s.currentLanguage}-${s.unitSystem}`
    }, [
      // HEADER
      m(Header, {
        app: attrs.app,
        isPublic,
        isMaintenance
      }),
      // MAIN
      m('.main.section', {
        className: !isPublic && !isMaintenance ? 'has-background-white' : ''
      }, m('.container.pb5', children)),
      // FOOTER
      m(Footer, {
        app: attrs.app,
        isMaintenance
      }),
      // POPUP NOTIFICATIONS
      m(PopupNotifications, {
        app: attrs.app
      }),
      // SYSTEM NOTIFICATIONS
      s.activeSystemNotification && m(Modal, {
        title: t('admin.system_notification'),
        close: () => {},
        noClose: true,
        // onClose: attrs.close,
        buttons: [
          m('button.button.is-primary', {
            onclick () {
              state.update({
                activeSystemNotification: {
                  ...s.activeSystemNotification,
                  isAccepted: true
                }
              })
            },
            type: 'button'
          }, t('common.accept'))
        ]
      }, [
        m('.message.is-info', [
          m('.message-body', s.activeSystemNotification.translations[s.currentLanguage])
        ])
      ]),
      // SOFTWARE UPDATE ALERT
      !env.isDevelop && s.hasUpdateAvailable && m('.update-notification', {
        oncreate ({ dom }) {
          setTimeout(() => dom.classList.add('popup-notification-animate'), 100)
        }
      }, [
        m('.notification is-info', [
          m('.flex.flex-cols', [
            m('.flex.pv2', t('user.software_update_available')),
            m('.has-text-right', m('button.button.is-danger.update-notification-button', {
              type: 'button',
              onclick () {
                window.location.reload(true)
              }
            }, t('common.refresh')))
          ])
        ])
      ]),
      // RESPONSIVE SIZE DEBUG HELPER
      env.isDebug && m(ResponsiveSize, {
        app: attrs.app
      })
    ])
  }

  return { view }
}

export default Layout
