import m from 'mithril'
import throttle from 'lodash.throttle'
import { env, localStorageKeys } from './config'
import { checkAuth } from './api'
import routes from './routes'
import { isAuth, invalidateAuth } from './models/auth'
import state from './models/state'
import { setupLogger } from './lib/log'
import routeResolver from './lib/route-resolver'
import { getViewport } from './lib/viewport'
import store from './lib/store'
import t from './lib/translate'
// Styles
import './scss/app.scss'

// Error logging
setupLogger()

// Polyfill for Dragula.js
if (typeof window.global === 'undefined') window.global = window

// Window resize listener for responsive behaviour
window.onresize = throttle(() => {
  state.update({ viewport: getViewport() })
  m.redraw()
}, 200)
state.update({ viewport: getViewport() })

// App routes
const appRoutes = {}
for (const key in routes) {
  appRoutes[key] = routeResolver(routes[key])
}

// Init app
Promise.all([
  isAuth()
    ? checkAuth()
      .catch(error => {
        if (error && error.status === 401) invalidateAuth()
      })
    : Promise.resolve(),
  // Init i18n and system of unit
  t.init(store.get(localStorageKeys.currentLanguage) || 'en'),
  new Promise(resolve => {
    state.update({
      systemNotifications: store.get(localStorageKeys.systemNotifications) || [],
      unitSystem: store.get(localStorageKeys.unitSystem) || 'SI'
    })
    // Minimum delay for initial animation
    setTimeout(resolve, env.isDevelop ? 0 : 1000)
  })
])
  .then(() => {
    // Mount the app
    const appContainer = document.getElementById('app')
    return m.route(appContainer, '/', appRoutes)
  })
  .catch(error => {
    console.log('Something went wrong in the app init')
    console.log(error)
  })
