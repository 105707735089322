// Components
const Admin = () => import('./views/admin/admin.js')
const AclGroups = () => import('./views/admin/acl-groups.js')
const AclRoles = () => import('./views/admin/acl-roles.js')
const Changelog = () => import('./views/changelog/changelog.js')
const CostingWizard1 = () => import('./views/costing-configurator/wizard-1.js')
const CostingWizard2 = () => import('./views/costing-configurator/wizard-2.js')
const CostingWizard3 = () => import('./views/costing-configurator/wizard-3.js')
const CostingWizard4 = () => import('./views/costing-configurator/wizard-4.js')
const CustomWizard1 = () => import('./views/custom-configurator/wizard-1.js')
const CustomWizard2 = () => import('./views/custom-configurator/wizard-2.js')
const CustomWizard3 = () => import('./views/custom-configurator/wizard-3.js')
const CustomWizard4 = () => import('./views/custom-configurator/wizard-4.js')
const Customer = () => import('./views/customers/customer.js')
const CustomerEdit = () => import('./views/customers/customer-edit.js')
const Customers = () => import('./views/customers/customers.js')
const EmailVerification = () => import('./views/user/email-verification.js')
const EnergeticAnalysisCustomCities = () => import('./views/energetic-analysis-custom/energetic-analysis-custom-cities.js')
const EnergeticAnalysisCustomCity = () => import('./views/energetic-analysis-custom/energetic-analysis-custom-city.js')
const Login = () => import('./views/user/login.js')
const Maintenance = () => import('./views/maintenance/maintenance.js')
const NewCustomer = () => import('./views/customers/new-customer.js')
const NewEnergeticAnalysisCustomCity = () => import('./views/energetic-analysis-custom/new-energetic-analysis-custom-city.js')
const NewUser = () => import('./views/users/new-user.js')
const Offer = () => import('./views/offers/offer.js')
const OfferPosition = () => import('./views/offers/offer-position.js')
const Offers = () => import('./views/offers/offers.js')
const PasswordForgot = () => import('./views/user/password-forgot.js')
const PasswordReset = () => import('./views/user/password-reset.js')
const PrintEnergeticAnalysis = () => import('./views/print/print-energetic-analysis.js')
const PrintEnergeticAnalysisRedirect = () => import('./views/print/print-energetic-analysis-redirect.js')
const PrintOffer = () => import('./views/print/print-offer.js')
const PrintOfferRedirect = () => import('./views/print/print-offer-redirect.js')
const PrintPositionSummary = () => import('./views/print/print-position-summary.js')
const PrintPositionSummaryRedirect = () => import('./views/print/print-position-summary-redirect.js')
const Profile = () => import('./views/user/profile.js')
const SignUp = () => import('./views/user/signup.js')
const SignupCompletion = () => import('./views/user/signup-completion.js')
const User = () => import('./views/users/user.js')
const UserEdit = () => import('./views/users/user-edit.js')
const Users = () => import('./views/users/users.js')
const Wizard1 = () => import('./views/configurator/wizard-1.js')
const Wizard2 = () => import('./views/configurator/wizard-2.js')
const Wizard3 = () => import('./views/configurator/wizard-3.js')
const Wizard4 = () => import('./views/configurator/wizard-4.js')

const routes = {
  '/': {},
  '/acl-groups': {
    component: AclGroups,
    isPublic: false,
    actions: ['acl.config']
  },
  '/acl-roles': {
    component: AclRoles,
    isPublic: false,
    actions: ['acl.config']
  },
  '/admin': {
    component: Admin,
    isPublic: false,
    actions: ['acl.drop_sessions']
  },
  '/changelog': {
    component: Changelog,
    isPublic: false,
    actions: []
  },
  '/configurator-wizard/1': {
    component: Wizard1,
    isPublic: false,
    actions: []
  },
  '/configurator-wizard/2': {
    component: Wizard2,
    isPublic: false,
    actions: []
  },
  '/configurator-wizard/3': {
    component: Wizard3,
    isPublic: false,
    actions: []
  },
  '/configurator-wizard/4': {
    component: Wizard4,
    isPublic: false,
    actions: []
  },
  '/costing-configurator-wizard/1': {
    component: CostingWizard1,
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/costing-configurator-wizard/2': {
    component: CostingWizard2,
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/costing-configurator-wizard/3': {
    component: CostingWizard3,
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/costing-configurator-wizard/4': {
    component: CostingWizard4,
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/custom-configurator-wizard/1': {
    component: CustomWizard1,
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator-wizard/2': {
    component: CustomWizard2,
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator-wizard/3': {
    component: CustomWizard3,
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator-wizard/4': {
    component: CustomWizard4,
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/customers': {
    component: Customers,
    isPublic: false,
    actions: []
  },
  '/customers/:key': {
    component: Customer,
    isPublic: false,
    actions: []
  },
  '/customers/:key/edit': {
    component: CustomerEdit,
    isPublic: false,
    actions: []
  },
  '/email-verification': {
    component: EmailVerification,
    isPublic: true
  },
  '/energetic-analysis-custom-cities': {
    component: EnergeticAnalysisCustomCities,
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/energetic-analysis-custom-cities/:key': {
    component: EnergeticAnalysisCustomCity,
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/login': {
    component: Login,
    isPublic: true
  },
  '/maintenance': {
    component: Maintenance,
    isPublic: true
  },
  '/new-customer': {
    component: NewCustomer,
    isPublic: false,
    actions: []
  },
  '/new-energetic-analysis-custom-city': {
    component: NewEnergeticAnalysisCustomCity,
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/new-user': {
    component: NewUser,
    isPublic: false,
    actions: ['users.create']
  },
  '/offers': {
    component: Offers,
    isPublic: false,
    actions: []
  },
  '/offers/:key': {
    component: Offer,
    isPublic: false,
    actions: []
  },
  '/offers/:offerId/positions/:key/:productType': {
    component: OfferPosition,
    isPublic: false,
    actions: []
  },
  '/password-forgot': {
    component: PasswordForgot,
    isPublic: true
  },
  '/password-reset': {
    component: PasswordReset,
    isPublic: true
  },
  '/print/energetic-analysis': {
    component: PrintEnergeticAnalysis,
    isPublic: false,
    actions: []
  },
  '/print/energetic-analysis-redirect': {
    component: PrintEnergeticAnalysisRedirect,
    isPublic: true
  },
  '/print/offer': {
    component: PrintOffer,
    isPublic: false,
    actions: []
  },
  '/print/offer-redirect': {
    component: PrintOfferRedirect,
    isPublic: true
  },
  '/print/position-summary': {
    component: PrintPositionSummary,
    isPublic: false,
    actions: []
  },
  '/print/position-summary-redirect': {
    component: PrintPositionSummaryRedirect,
    isPublic: true,
    actions: []
  },
  '/profile': {
    component: Profile,
    isPublic: false,
    actions: []
  },
  '/signup': {
    component: SignUp,
    isPublic: true
  },
  '/signup-completion': {
    component: SignupCompletion,
    isPublic: true
  },
  '/users': {
    component: Users,
    isPublic: false,
    actions: ['users.list']
  },
  '/users/:key': {
    component: User,
    isPublic: false,
    actions: ['users.detail']
  },
  '/users/:key/edit': {
    component: UserEdit,
    isPublic: false,
    actions: ['users.edit']
  }
}

export default routes
