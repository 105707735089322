import { captureMessage, init, withScope } from '@sentry/browser'
import { currentVersion, env, sentryDSN } from '../config'

// Log errors to Sentry
export const setupLogger = () => {
  if (env.isProduction) {
    // See Sentry docs for configuration
    // https://docs.sentry.io/platforms/javascript/configuration/
    init({
      dsn: sentryDSN,
      environment: env.current
    })
  }
}

export const log = error => {
  if (env.isProduction) {
    // Skip logs for this kind of error
    if ((error.status && error.status >= 300) || (error.message && error.message.includes('onbeforeupdate'))) return
    // Log everething else
    withScope(scope => {
      // Enrich error data
      // https://docs.sentry.io/platforms/javascript/enriching-events/scopes/
      scope.setTag('captureUnhandledRejections', true)
      scope.setTag('currentVersion', currentVersion)
      // Capture error
      const exception = `[HTTP ${error.status}] ${error.title} - ${error.detail}`
      captureMessage(exception)
    })
  } else {
    console.log('[ERROR]', error)
  }
}
