import m from 'mithril'
import { currentVersion } from '../../config'
import { isAuth } from '../../models/auth'
import logo from '../../img/logo.svg'

const Footer = () => {
  function view ({ attrs }) {
    const showChangelog = isAuth() && !attrs.isMaintenance
    return m('.footer.section.pv4.has-background-light', [
      m('.container.has-text-grey', [
        m('img.footer-logo-image', {
          alt: 'Selection Tool',
          src: logo
        }),
        'Selection Tool',
        showChangelog && [
          ` v.${currentVersion} - `,
          m(m.route.Link, {
            href: '/changelog'
          }, 'Changelog')
        ],
        m('br'),
        'Powered by ',
        m('a', {
          href: 'https://www.mvlabs.it',
          target: '_blank',
          rel: 'noopener noreferrer'
        }, 'MV Labs')
      ])
    ])
  }

  return { view }
}

export default Footer
