import dlv from 'dlv'
import { klona } from 'klona'
import { deepEqual } from 'fast-equals'

// Deep clone Objects, Arrays, Dates, RegExps, and more
// > const output = objClone(input)
export const objClone = klona

export const objFlatten = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const key = prefix.length ? `${prefix}${k.charAt(0).toUpperCase()}${k.slice(1)}` : k
    if (typeof obj[k] === 'object') Object.assign(acc, objFlatten(obj[k], key))
    else acc[key] = obj[k]
    return acc
  }, {})

// Safely get a dot-notated path within a nested object, with ability to return a default if the full key path
// does not exist or the value is undefined
// > objSafeGet(object, keypath, [default])
export const objSafeGet = dlv

export const formatObjectValues = obj => Object.keys(obj).forEach(k => {
  if (typeof obj[k] === 'string') {
    obj[k] = obj[k]
      ? parseFloat(obj[k])
      : null
  }
})

export const objDeepEqual = deepEqual
