const isProduction = window.location.hostname === 'selectiontool.refrion.com'

export default [
  !isProduction && {
    id: 'cs',
    name: 'Czech'
  },
  {
    id: 'de',
    name: 'Deutsch'
  },
  {
    id: 'en',
    name: 'English'
  },
  !isProduction && {
    id: 'es',
    name: 'Español'
  },
  {
    id: 'fr',
    name: 'Français'
  },
  {
    id: 'it',
    name: 'Italiano'
  },
  {
    id: 'ru',
    name: 'Русский'
  },
  !isProduction && {
    id: 'sv',
    name: 'Swedish'
  }
].filter(x => x)
