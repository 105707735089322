import stream from 'mithril/stream'
import mergerino from 'mergerino'
import store from './store'

export const createState = (reducer = mergerino, initial = {}, storageKey) => {
  const update = stream()
  let local
  if (storageKey) {
    local = store.get(storageKey) || {}
    if (initial.version !== local.version) {
      local = undefined
    }
  }
  const state = stream.scan(reducer, {
    ...initial,
    ...local,
    key: Date.now()
  }, update)

  // if there might be async updates
  // state.map(m.redraw)

  state.update = update
  state.reset = () => {
    if (storageKey) store.remove(storageKey)
    update({
      ...initial,
      key: Date.now(),
      reset: true
    })
  }

  return state
}
