import m from 'mithril'
import NavBarDropdown from '../common/dropdown/navbar-dropdown'
import { env } from '../../config'
import state from '../../models/state'
import t from '../../lib/translate'
import languages from '../../data/languages'

const LanguagePicker = () => {
  function view () {
    const currentLanguage = state().currentLanguage
    return m(NavBarDropdown, {
      text: [
        m('span.is-hidden-tablet', t('nav.language')),
        m('span.is-hidden-mobile', currentLanguage.toUpperCase())
      ]
    }, languages.map(language => currentLanguage === language.id
      ? m('span.navbar-item', [
        m('img.navbar-flag.mr2.vamid', {
          src: `${env.CDNBaseURL}/flags/${language.id}.png`,
          alt: language.id
        }),
        m('strong.has-text-primary', language.name)
      ])
      : m('a.navbar-item', {
        onclick (e) {
          e.preventDefault()
          return t.init(language.id)
        }
      }, [
        m('img.navbar-flag.mr2.vamid', {
          src: `${env.CDNBaseURL}/flags/${language.id}.png`,
          alt: language.id
        }),
        language.name
      ])
    ))
  }

  return { view }
}

export default LanguagePicker
