import m from 'mithril'
import t from '../../../lib/translate'

const SubmitButton = {
  view ({ attrs, children }) {
    return m('button.button.is-uppercase', {
      className: [
        attrs.className || 'is-primary', // optional
        attrs.loading ? 'is-loading' : ''
      ].join(' ').trim(),
      type: attrs.type || 'submit', // optional
      value: attrs.value, // optional
      onclick () {
        if (attrs.onClick && typeof attrs.onClick === 'function') attrs.onClick()
      },
      disabled: attrs.disabled // optional
    }, children && children.length ? children : t('common.save_apply')) // optional
  }
}

export default SubmitButton
