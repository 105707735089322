import m from 'mithril'
import Icon from '../icon/icon'
import { notifications, current, close } from '../../../lib/popup-notifications'
import t from '../../../lib/translate'

const PopupNotifications = () => {
  let closeTimeout, monitorNotifications

  const clearCloseTimeout = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
      closeTimeout = null
    }
  }

  const startCloseTimeout = notification => {
    clearCloseTimeout()
    closeTimeout = setTimeout(() => {
      if (!notification.permanent) forceClose(notification.id)
    }, notification.duration || 4000)
  }

  const forceClose = (id) => {
    clearCloseTimeout()
    close(id)
  }

  function oncreate () {
    notifications([])
    monitorNotifications = notifications.map(m.redraw)
  }

  function onremove () {
    if (monitorNotifications) monitorNotifications.end(true)
  }

  function view () {
    const notification = current()
    return m('.popup-notifications', notification ? m('.popup-notification.message', {
      className: `is-${notification.type}`,
      key: `popup-notification-${notification.id}`,
      oncreate ({ dom }) {
        setTimeout(() => dom.classList.add('popup-notification-animate'), 100)
        startCloseTimeout(notification)
      },
      onbeforeremove ({ dom }) {
        dom.classList.remove('popup-notification-animate')
        return new Promise((resolve) => {
          setTimeout(resolve, 250)
        })
      },
      onmouseover: () => clearCloseTimeout(),
      onmouseleave: () => startCloseTimeout(notification)
    }, m('.message-body', [
      m('button.nobtn.popup-notification-close', {
        title: t('common.close'),
        onclick () {
          clearCloseTimeout()
          close(notification.id)
        },
        type: 'button'
      }, m(Icon, {
        name: 'faTimes'
      })),
      m('.flex.flex-cols', {
        onclick: notification.onclick
      }, [
        m(Icon, {
          name: notification.type === 'info'
            ? 'faInfo'
            : 'faExclamationTriangle',
          classes: ['fa-fw', 'fa-2x', 'mr3']
        }),
        m('.pt1', notification.message)
      ])
    ])) : null)
  }

  return {
    oncreate,
    onremove,
    view
  }
}

export default PopupNotifications
